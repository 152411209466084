import './App.css';

function App() {
  return (
    <div className='container'>
        {/* animation and backgrounds only */}
        <div id='background'>
          <div style={{width:"100vw"}}>
            <img src='/saturn.png' className='saturn'/>
            <div className='background1' ></div>
          </div>
          <box className="alien-box">
            <img src='/alienhand.png' className='hand' />
            <img src='/alien.png' className='alien'/>
            <div className='background3' style={{marginLeft:"-150px"}}></div>
          </box>
          <img src='/rock.png' className='rock' />
          <img src='/telescope.png' className='telescope' />
          <img src='/spacewalker.png' className='spacewalker' />

          <section id='section'>
              <span id='span'></span>
              <span id='span'></span>
              <span id='span'></span>
              <span id='span'></span>
              <span id='span'></span>
              <span id='span'></span>
              <span id='span'></span>
              <span id='span'></span>
              <span id='span'></span>
              <span id='span'></span>
          </section>

          <box className="alienship_box" >
            <img src='/light.png' className='light' />
            <img src='/alienship.png' className='alienship'  />
          </box>
          <div className='background'></div>
          <div className='background2'></div>
        </div>

        {/* navigation */}
        <div className='nav'>
          <span className='logo'>Alish</span>
        </div>

        {/* title and contents starts here1 */}
        <div className='mainTitleContainer'>
          <span className='title'>EXPLORE</span>
          <span className='title'>THE SPACE</span>
          <br></br>
          <span className='subtitle'>Don't be afrait to take quantum leap!!</span>
        </div>

      </div>
  );
}

export default App;
